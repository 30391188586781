.list_page {
  display: grid;
  grid-template-columns: 17% 83%;
  justify-content: center;
  width: 100%;

  .list_page_main {
    .data_table {
      padding: 10px 10px;

      .btnn {
        margin-bottom: 10px;
        margin-right: 10px;
        button {
          margin-top: 15px;
          display: block;
          background: none;
          border: 1px solid#4665fdde;
          padding: 8px 15px;
          background-color: #4665fdde;
          color: white;
          font-size: 17px;
          border-radius: 4px;
          cursor: pointer;
          margin-left: auto;
          transition: all 0.3s ease;
          text-transform: capitalize;

          &:hover {
            background-color: white;
            color: #4665fdde;
            border: 1px solid#4665fdde;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .list_page {
    display: grid;
    grid-template-columns: 100%;
    justify-content: center;

    .home_sidebar {
      display: none;
    }
  }
}
