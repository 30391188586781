.home {
  display: grid;
  grid-template-columns: 17% 83%;
  justify-content: center;

  .home_main {
    .bg_color {
      width: 100%;
      height: 160px;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      background-color: #4665fdde;
    }
    .home_items {
      display: grid;
      grid-template-columns: 23% 23% 23% 23%;
      gap: 20px;
      padding: 20px 25px;
      // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      margin-top: -80px;
    }

    /* The `.chart_sec` class is defining the styles for a section that contains a grid layout. */
    .chart_sec {
      display: grid;
      grid-template-columns: 25% 75%;
      justify-content: center;
      gap: 20px;
      padding: 20px 20px;
      box-sizing: border-box;
    }

    .table {
      margin-top: 120px;

      padding: 20px 25px;
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

      .title {
        padding: 0px 0px 15px;
        color: gray;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .home {
    display: grid;
    grid-template-columns: 100%;
    justify-content: center;

    .home_sidebar {
      display: none;
    }

    .home_main {
      .bg_color {
        // width: 100%;
        // height: 160px;
        // border-bottom-right-radius: 20px;
        // border-bottom-left-radius: 20px;
        // background-color: #4665fdde;
      }
      .home_items {
        display: grid;
        grid-template-columns: 31% 31% 31%;
        gap: 20px;
      }

      .chart_sec {
        display: grid;
        grid-template-columns: 100%;
        justify-content: center;
        gap: 20px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .home {
    .home_main {
      .bg_color {
        // width: 100%;
        // height: 160px;
        // border-bottom-right-radius: 20px;
        // border-bottom-left-radius: 20px;
        // background-color: #4665fdde;
      }
      .home_items {
        display: grid;
        grid-template-columns: 48% 48%;
        gap: 20px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .home {
    .home_main {
      .bg_color {
        // width: 100%;
        height: 100px;
        // border-bottom-right-radius: 20px;
        // border-bottom-left-radius: 20px;
        // background-color: #4665fdde;
      }
      .home_items {
        display: grid;
        grid-template-columns: 100%;
        justify-content: center;
        gap: 20px;
      }
    }
  }
}
