.details {
  display: grid;
  grid-template-columns: 17% 83%;
  justify-content: center;

  .detail_page_main {
    .user_info {
      display: grid;
      grid-template-columns: 22% 70%;
      align-items: center;
      justify-content: center;
      gap: 30px;
      padding: 30px 25px;

      .user_detail {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        text-align: center;
        padding: 20px;
        border-radius: 3px;
        flex: 3;
        height: fit-content;

        .user_image {
          height: 130px;
          width: 130px;
          border-radius: 50%;
          object-fit: cover;
        }

        .user_detailss {
          margin-top: 10px;

          p {
            margin-bottom: 10px;
          }

          .name {
            font-size: 30px;
          }
        }
      }

      .user_chart {
        flex: 9;
      }
    }

    .table {
      padding: 20px 25px;

      .title {
        color: gray;
        margin-bottom: 15px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .details {
    display: grid;
    grid-template-columns: 100%;
    justify-content: center;
    .home_sidebar {
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .details {
    display: grid;
    grid-template-columns: 100%;
    justify-content: center;

    .detail_page_main {
      .user_info {
        display: grid;
        grid-template-columns: 100%;
        justify-content: center;
        gap: 30px;
      }
    }
  }
}

.details {
    display: flex;
}

.home_sidebar {
    width: 250px;
}

.detail_page_main {
    flex: 1;
    padding: 20px;
}

.product_info {
    display: flex;
    flex-direction: column;
}

.product_detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.product_images {
    display: flex;
    flex-direction: column;
}

.product_image {
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
}

.product_details {
    flex: 1;
    margin-left: 20px;
}

.product_reviews {
    margin-top: 20px;
}

.review {
    margin-bottom: 10px;
}

