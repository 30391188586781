.table_list {
  //   box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

  .product_idd {
    display: flex;
    align-items: center;
    .product_img {
      height: 36px;
      width: 36px;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 10px;
    }
  }

  .status {
    &.Approved {
      color: green;
    }
    &.Pending {
      color: #ff74b1;
    }
  }
}
