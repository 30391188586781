.list_page {
    display: flex;
    flex-direction: row;
    height: 100vh;

    .home_sidebar {
        width: 250px; // Sidebar width
        background-color: #f4f4f4;
        border-right: 1px solid #ddd;
        padding: 20px;
        overflow-y: auto; // Allow scrolling if content overflows
    }

    .list_page_main {
        flex: 1;
        padding: 20px;
        background-color: #fff;
        overflow-y: auto; // Allow scrolling if content overflows
    }

    .sliders_table_container {
        margin-top: 20px;

        .table_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            h2 {
                font-size: 24px;
                color: #333;
            }

            Button {
                font-size: 16px;
            }
        }

        .slider_table {
            width: 100%;
            border-collapse: collapse;

            th {
                background-color: white; // Primary color for header
                color: white;
                padding: 12px;
                text-align: left;
            }

            td {
                padding: 12px;
                border-bottom: 1px solid #ddd;
                text-align: left;

                img.slider_image {
                    width: 100px; // Fixed width for images
                    height: auto;
                }
            }

            tr:hover {
                background-color: #f1f1f1; // Highlight row on hover
            }
        }
    }

    .loading_spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}

.delete_btn {
    padding: 5px 12px;
    cursor: pointer;
    border-radius: 4px;
    border: none;
    color: #ff74b0f5;
    border: 1px solid #ff74b0cb;
    background: none;
  }