.data_table {
  height: 660px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .userr {
    display: flex;
    align-items: center;

    .userr_image {
      height: 36px;
      width: 36px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 10px;
    }
  }

  .status {
    //
    &.active {
      color: green;
    }
    &.passive {
      color: #ff74b1;
    }
  }

  .actionn {
    display: flex;
    align-items: center;
    // justify-content: space-between;

    .view_btn {
      padding: 5px 12px;
      cursor: pointer;
      border-radius: 4px;
      border: none;
      margin-right: 10px;
      color: #7551f8d2;
      border: 1px solid #7551f8bd;
      background: none;
    }

    .delete_btn {
      padding: 5px 12px;
      cursor: pointer;
      border-radius: 4px;
      border: none;
      color: #ff74b0f5;
      border: 1px solid #ff74b0cb;
      background: none;
    }
  }
}

.data_grid .MuiDataGrid-cell {
    white-space: normal;
    word-wrap: break-word;
    display: block;
}

.wrapContent {
    white-space: normal !important;
    word-wrap: break-word !important;
    overflow: visible !important;
    justify-content: center;
    padding: 10px 10px !important;
}

 .search {
      display: flex;
      align-items: center;
      border-radius: 3px;
      border: 1px solid #3d3c42e1;
      position: relative;
      width: 200px;

      input {
        border: none;
        outline: none;
        max-width: 20;
        font-size: 14px;
        padding: 6px 10px;
        // color: rgba(255, 255, 255, 0.925);
      }

      .search_icon {
        cursor: pointer;
        color: #3d3c42e1;
        position: absolute;
        right: 5px;
      }
    }